<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Register v1 -->
            <b-card class="mb-0">
                <b-link class="display-logo brand-logo">
                    <logo class="margin-logo" />
                    <h2 class="brand-text text-primary title-kalam mt-1">
                        {{ title }}
                    </h2>
                </b-link>

                <b-card-title v-if="finance" class="mb-1">
                    Start tracking team performance 🚀
                </b-card-title>
                <b-card-text class="mb-2"> Create your account </b-card-text>

                <!-- form -->
                <validation-observer ref="registerForm">
                    <b-form
                        class="auth-register-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- firstname -->
                        <b-form-group label="First name" label-for="firstname">
                            <validation-provider
                                #default="{ errors }"
                                name="First name"
                                rules="required"
                            >
                                <b-form-input
                                    id="firstname"
                                    v-model="firstname"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-firstname"
                                    placeholder="John"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- username -->
                        <b-form-group label="Last name" label-for="lastname">
                            <validation-provider
                                #default="{ errors }"
                                name="Last name"
                                rules="required"
                            >
                                <b-form-input
                                    id="lastname"
                                    v-model="lastname"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-lastname"
                                    placeholder="Mayer"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group label="Email" label-for="email">
                            <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="regEmail"
                                    :state="errors.length > 0 ? false : null"
                                    name="register-email"
                                    placeholder="john@example.com"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group label="Password" label-for="password">
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="password"
                                        :type="passwordFieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="register-password"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group
                            label="Repeat your password"
                            label-for="password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Password"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="password"
                                        v-model="repeatPassword"
                                        :type="passwordFieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="register-password"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            :icon="passwordToggleIcon"
                                            class="cursor-pointer"
                                            @click="togglePasswordVisibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            Sign up
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'login' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Register v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from "bootstrap-vue"
import { required, email } from "@validations"
import { togglePasswordVisibility } from "@core/mixins/ui/forms"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Auth from "@/api/services/Auth.js"
import Logo from "@/components/Logo.vue"
import { $themeConfig } from "@themeConfig"

export default {
    components: {
        // BSV
        BCard,
        BLink,
        BCardTitle,
        BCardText,
        BForm,
        BButton,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        // validations
        ValidationProvider,
        ValidationObserver,
        Logo,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            regEmail: "",
            username: "",
            password: "",
            status: "",
            firstname: "",
            lastname: "",
            // validation rules
            required,
            email,
            title: "",
            finance: false,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon"
        },
    },
    created() {
        this.title = $themeConfig.app.appLogoDescription.title
        this.finance = $themeConfig.app.appName === "Finance"
    },
    methods: {
        validationForm() {
            this.$refs.registerForm.validate().then((success) => {
                if (success) {
                    this.signUp()
                }
            })
        },
        signUp() {
            this.$loading(true)
            Auth.signUp({
                username: this.regEmail,
                first_name: this.firstname,
                last_name: this.lastname,
                email: this.regEmail,
                password: this.password,
            })
                .then((response) => {
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Success",
                                icon: "CheckIcon",
                                text: "Your account has been successfully created",
                                variant: "success",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Important",
                                icon: "InfoIcon",
                                text: "Please ask an admin to activate your account",
                                variant: "warning",
                            },
                        },
                        {
                            position: "bottom-center",
                            timeout: 10000,
                        }
                    )
                })
                .catch((error) => {
                    let message = ""
                    Object.entries(error.response.data).forEach(
                        ([key, value]) => {
                            if (key === "username") {
                                key = "email"
                                value = String(value).replace(
                                    "username",
                                    "email"
                                )
                            }
                            message =
                                message + (message === "" ? "" : "; ") + value
                        }
                    )
                    this.$loading(false)
                    this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "AlertTriangleIcon",
                                text: message,
                                variant: "danger",
                            },
                        },
                        {
                            position: "bottom-center",
                        }
                    )
                })
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
